import en from "vuetify/lib/locale/en";
export default {
  ...en,
  search: "Search",
  job_title: "Job Title",
  location: "Location",
  apply: "Apply",
  details: "Details",
  read_more: "Read More",
  share_job_on: "Share the job on",
  find_jobs: "Find Jobs",
  powered_by: "Powered By",
  job_details: "Job Details",
  negotiable: "Negotiable",
  back: "Back",
  f_name: "First Name",
  l_name: "Last Name",
  email: "Email",
  phone: "Phone",
  experience: "Experience",
  click_here: "Click Here",
  cover_letter: "Cover Letter",
  questions: "Questions",
  apply_before: "Apply Before",
  apply_now: "Apply Now",
  copy_text: "Copy link to clipboard",
  close: "Close",
  home: {
    search_position: "Search Position",
    search_placeholder: "Job, Title, Keyword or Company",
  },
  apply_job: {
    apply_for_job: "Apply for this job",
  },
};
