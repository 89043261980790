<template>
  <v-app>
    <v-app-bar app color="#fff">
      <img
        src="@/assets/logo.png"
        class="img-fluid logo-img"
        alt=""
        v-if="!$store.state.company"
        @click="$router.push('/')"
      />

      <h1 class="brand primary--text mr-5" v-else @click="$router.push('/')">
        {{ $store.state.company.name }}
      </h1>

      <p class="find-job mb-0 mx-3">
        {{ $vuetify.lang.t("$vuetify.find_jobs") }}
      </p>

      <v-spacer></v-spacer>

      <div class="mx-4 language-dropdown">
        <v-select
          hide-details
          v-model="language"
          :items="languages"
          @change="languageChange"
        ></v-select>
      </div>
    </v-app-bar>
    <v-main>
      <router-view />
    </v-main>

    <v-footer padless absolute>
      <v-col class="text-center d-flex justify-center align-center" cols="12">
        <p class="mb-0" style="font-weight: bold">
          {{ $vuetify.lang.t("$vuetify.powered_by") }}
        </p>
        <img src="@/assets/logo.png" class="img-fluid logo-img" alt="" />
      </v-col>
    </v-footer>

    <notifications
      group="backend-error"
      position="top right"
      animation-type="velocity"
      class="error-notification"
    />

    <div style="padding-bottom: 100px"></div>
  </v-app>
</template>

<script>
let language = { value: "en", rtl: false };
if (localStorage.getItem("lang")) {
  language = JSON.parse(localStorage.getItem("lang"));
}

export default {
  name: "App",

  data: () => ({
    language: language.value,
    languages: [
      { text: "English | en", value: "en", rtl: false },
      { text: "Arabic | ar", value: "ar", rtl: true },
    ],
  }),
  methods: {
    languageChange(value) {
      const lang = this.languages.find((lang) => lang.value === value);
      localStorage.setItem("lang", JSON.stringify(lang));
      this.$vuetify.lang.current = lang.value;
      this.$vuetify.rtl = lang.rtl;
    },
  },
};
</script>

<style lang="scss">
.img-fluid {
  max-width: 100%;
  height: auto;
}

.logo-img {
  width: 100px;
  margin-right: 2rem;
  margin-left: 1rem;
  cursor: pointer;
}
.find-job {
  font-weight: 500;
  border-bottom: 2px solid #33aada;
  padding-bottom: 3px;
  margin-bottom: 0;

  @media (max-width: 769px) {
    display: none;
  }
}

.v-application {
  .v-btn {
    text-transform: capitalize;
    letter-spacing: 0;
  }

  .h-100 {
    height: 100%;
  }
}

.brand {
  cursor: pointer;
}
</style>
