import ar from "vuetify/lib/locale/ar";
export default {
  ...ar,
  search: "بحث",
  job_title: "لقب عنوان وظيفي",
  location: "موقع الوظيفة",
  apply: "تطبيق",
  details: "تفاصيل",
  read_more: "اقرأ أكثر",
  share_job_on: "شارك الوظيفة على",
  find_jobs: "العثور على وظائف",
  powered_by: "مشغل بواسطة",
  job_details: "تفاصيل الوظيفة",
  negotiable: "قابل للتفاوض",
  back: "خلف",
  f_name: "الاسم الأول",
  l_name: "الكنية",
  email: "بريد الالكتروني",
  phone: "هاتف",
  experience: "خبرة",
  click_here: "انقر لها",
  cover_letter: "غطاء الرسالة",
  questions: "أسئلة",
  apply_before: "تقدم قبل",
  apply_now: "تقدم قبل",
  copy_text: "قدم الآن",
  close: "قريب",
  home: {
    search_position: "موقف البحث",
    search_placeholder:
      "الوظيفة أو المسمى الوظيفي أو الكلمة الرئيسية أو الشركة",
  },
  apply_job: {
    apply_for_job: "تقدم لهذه الوظيفة",
  },
};
